<div
  #originRef
  (click)="showPopover(originRef)"
  (keydown.enter)="showPopover(originRef)"
  [class.bg-hover]="dropdownOpen"
  tabindex="0"
  class="flex items-center w-full rounded cursor-pointer relative transition duration-400 ease-out-swift select-none p-3 hover:bg-hover"
  matRipple>
  <div class="avatar flex w-full ">
    <div class="w-9 h-9 rounded-full overflow-hidden">
      <img
        [src]="'../../../../../assets/img/avatars/1.jpg'"
        class="w-full h-full object-cover"
        alt="Avatar" />
    </div>
    <div class="details hidden md:flex flex-col justify-center">
      <div class="username font-semibold text-[15px]">John Mathew</div>
      <div class="email text-[10px]">johngmail.com</div>
    </div>
  </div>
</div>

<!-- <div
    class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">
    David Smith
  </div>
  <div
    class="rounded-full h-9 w-9 flex items-center justify-center text-primary-600 bg-primary-600/10">
    <mat-icon svgIcon="mat:person"></mat-icon>
  </div> -->
